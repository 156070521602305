import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App2 from "./App2";
import App1 from "./App1";
import AppFront from "./AppFront";
import InfoMemoPage from "./InfoMemoPage";
import ResponsePage from "./ResponsePage";

window.request = ""
function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<AppFront/>} />
                <Route exact path="/alvogpt" element={<App1/>} />
                <Route exact path="/alvogpt/response" element={<ResponsePage/>} />
                <Route exact path="/teaser" element={<App2/>} />
                <Route exact path="/teaser/content" element={<InfoMemoPage/>} />
            </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
