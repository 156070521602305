import React, { useState } from 'react';
import './App.css';
import AlvoGPT from './Alvo GPT.svg';
import AlvoInfoMemo from './Alvo info memo.svg';
import Logo from './Logo Alvo.svg';
import DesktopAlvoGPT from './GPT V3.svg';
import DesktopInfoMemo from './IM V3.svg'
import sendIcon from './Vector.svg';
import { useNavigate } from 'react-router-dom'

const AppFront = () => {
  const [activeButton, setActiveButton] = useState('AlvoInfoMemo');
  const navigate = useNavigate();
  const onButtonClick = () => {
    if (activeButton === 'AlvoGPT') {
      navigate('/alvogpt');
    } else {
      navigate('/teaser');
    }
  };
  const handleToggle = (buttonName) => {
    setActiveButton(buttonName);
  };

  const getContentText = () => {
    if (activeButton === 'AlvoGPT') {
      return "La recherche d’offres de cession et de reprise sur l’ensemble du marché.";
    } else if (activeButton === 'AlvoInfoMemo') {
      return "Générez votre teaser en 1 clic à l'aide de notre moteur IA. Saisissez l'URL du site ou de la marque souhaité.";
    }
  };

  const getTitleText = () => {
    if (activeButton === 'AlvoGPT') {
      return 'AlvoGPT';
    } else if (activeButton === 'AlvoInfoMemo') {
      return 'Teaser';
    }
  };

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate2 = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidURL(inputValue)) {
      navigate2('/teaser/content');
    } else {
      setErrorMessage('URL invalide. Veuillez entrer une URL valide.');
    }
  };


  const handleInputChange = (e) => {
    const value = e.target.value;
    const modifiedValue = removePrefix(value);

    setInputValue(modifiedValue);
    localStorage.setItem('request', modifiedValue);
  };

  const isValidURL = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');

    return !!pattern.test(url);
  };

  const removePrefix = (value) => {
    if (value.startsWith('https://')) {
      return value.replace('https://', '');
    }
    return value;
  };

  return (
    <div>
      <header className="header">
        <div className='headerWidth'>
          <div className="logo">
            <a href='https://alvo.market/' target='blank'><img src={Logo} alt="Logo" /></a>
          </div>
          <nav className="nav">
            <ul>
              <a href="https://calendly.com/juliette-alvo/" target='blank'><li className="startNow">Demander une démo</li></a>
            </ul>
          </nav>
        </div>
      </header>
      <div className="container">
        <div className="toggle">
        <button
            className={activeButton === 'AlvoInfoMemo' ? 'active' : ''}
            onClick={() => handleToggle('AlvoInfoMemo')}
          >
            Teaser
          </button>
          <button
            className={activeButton === 'AlvoGPT' ? 'active' : ''}
            onClick={() => handleToggle('AlvoGPT')}
          >
            AlvoGPT
          </button>
        </div>
        <div className="content">
          <div key={activeButton} className={`container-left ${activeButton !== '' ? 'slide-up' : ''}`}>
            <h1 className='title2'>{getTitleText()}<span className="beta2">BETA</span></h1>
            <p>
              {getContentText()}
            </p>
            {activeButton === 'AlvoGPT' && (
              <div className='TestNowDiv'><a href='/alvogpt' className='TestNow'>Testez maintenant !</a></div>
            )}
            {activeButton === 'AlvoInfoMemo' && (
            <div className="input-container2">
              <input
                className="chat-input2"
                type="text"
                placeholder="Entrez l'URL du site web de la société..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              />
              <button className="send-button2" onClick={handleSubmit}>
                <img src={sendIcon} alt="Send" className="send-icon" />
              </button>
            </div>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          <div className={`container-right ${activeButton !== '' ? 'slide-up' : ''}`}>
            {activeButton === 'AlvoGPT' && (
              <div>
                <img src={AlvoGPT} alt="AlvoGPT" className={`slide-up ${activeButton !== '' ? 'slide-up' : ''} desktopImg`} />
                <img src={DesktopAlvoGPT} alt="AlvoGPT" className={`slide-up ${activeButton !== '' ? 'slide-up' : ''} mobileImg`} />
              </div>
            )}
            {activeButton === 'AlvoInfoMemo' && (
              <div>
                <img src={AlvoInfoMemo} alt="AlvoInfoMemo" className={`slide-up ${activeButton !== '' ? 'slide-up' : ''} desktopImg`} />
                <img src={DesktopInfoMemo} alt="AlvoInfoMemo" className={`slide-up ${activeButton !== '' ? 'slide-up' : ''} mobileImg`} />
              </div>
            )}
          </div>
          {activeButton === 'AlvoGPT' && (
            <button className='lowerButton' onClick={onButtonClick}>Testez maintenant !</button>
          )}
        </div>
        <div className='scroll-svg'>
          <svg width="30px" height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink dé="http://www.w3.org/1999/xlink" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5' }}>
	<path id="wheel" d="M123.359,79.775l0,72.843" style={{ fill: 'none', stroke: '#fff', strokeWidth: '20px' }}/>
	<path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" style={{ fill: 'none', stroke: '#fff', strokeWidth: '10px' }}/>
</svg>
        </div>
      </div>
      <div className='prod-block'>
        <div className='prod-block-left'>
          <h1>Suite Pro Alvo</h1>
          <p>Outil de gestion et production des mandats de cession pour les PME.</p>
          <a href='https://calendly.com/juliette-alvo/'>Demander une démo</a>
          <img src={AlvoGPT} alt="AlvoGPTIcon" className='block-img'/>
        </div>
        <div className='prod-block-right'>
          <div className='prod-block-right-blocks'>
            <div className='prod-block-right-title'>
              <h1>01</h1>
              <h2>Une gestion optimisée des mandats</h2>
            </div>
            <p><span className="icon">:</span> Donnez accès à vos équipes et pilotez finement les droits de chacun</p>
            <p><span className="icon">:</span> Offrez de la visibilité et un suivi en ligne (documents, liste de repreneurs etc) aux cédants et aux repreneurs.</p>
          </div>
          <div className='prod-block-right-blocks'>
          <div className='prod-block-right-title blocks-middle'>
              <h1>02</h1>
              <h2>Une solution personnalisable</h2>
          </div>
          <p><span className="icon">:</span> Personnalisez vos éléments de communication</p>
          <p><span className="icon">:</span> Adaptez l'outil à vos process et utilisez vos modèles des documents</p>
          </div>
          <div>
            <div className='prod-block-right-title blocks-middle'>
              <h1>03</h1>
              <h2>Un accompagnement métier</h2>
            </div>
            <p><span className="icon">:</span> Déléguez à Alvo certaines étapes de production</p>
            <p><span className="icon">:</span> Accompagnement et conseil de nos experts à la demande</p>
          </div>
        </div>
      </div>
      <div className="footer-infos">
        <div className='footer-logo'>
          <a href="https://www.alvo.market" target="_blank" rel='noreferrer'>
            <img src={Logo} alt="Alvo"/>
          </a>
        </div>
        <div className='footer-socials'>
          <a href="https://www.facebook.com/getalvo" rel="noreferrer" target="_blank" className='icon'>
          f
          </a>
          <a href="https://twitter.com/Alvo_market" rel="noreferrer" target="_blank" className='icon'>
          t
          </a>
          <a href="https://www.linkedin.com/company/alvo-market/" rel="noreferrer" target="_blank" className='icon'>
          i
          </a>
          <a href="https://www.instagram.com/alvo.market/" rel="noreferrer" target="_blank" className='icon'>
          c
          </a>
        </div>
        <div className="footer-links">
          <a href="https://www.alvo.market/blog" target="_blank" rel="noreferrer"class="footer_subnav__item">Blog</a>
          <a href="https://www.alvo.market/annuaire" target="_blank" rel="noreferrer" class="footer_subnav__item">Intermédiaires et conseils</a>
          <a href="https://teamalvo.notion.site/Presse-M-dia-Alvo-market-267551923d3849b2a8930d774bcd20f8" rel="noreferrer" target="_blank" class="footer_subnav__item">Presse</a>
          <a href="https://www.alvo.market/about" target="_blank" rel="noreferrer" class="footer_subnav__item">Qui sommes-nous ?</a>
          <a href="https://teamalvo.notion.site/Alvo-recrute-Rejoignez-nous-2046de58ecf94d2f9d6fd4223ccb04d7" rel="noreferrer"target="_blank" class="footer_subnav__item">Alvo recrute. Rejoignez-nous !</a>
          <a href="https://www.alvo.market/nous-contacter" target="_blank" rel="noreferrer" class="footer_subnav__item">Contact</a>
          <a href="https://www.alvo.market/cgu" target="_blank" rel="noreferrer" class="footer_subnav__item">CGU</a>
          <a href="https://www.alvo.market/mentions-legales" target="_blank" rel="noreferrer" class="footer_subnav__item">Mentions légales</a>
        </div>
      </div>
    </div>
  );
};

export default AppFront;
