import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App2.css';
import sendIcon from './Vector.svg';
import IM from './Image info memo (1).svg';

const App2 = () => {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidURL(inputValue)) {
      navigate('/teaser/content');
    } else {
      setErrorMessage('URL invalide. Veuillez entrer une URL valide.');
    }
  };


  const handleInputChange = (e) => {
    const value = e.target.value;
    const modifiedValue = removePrefix(value);

    setInputValue(modifiedValue);
    localStorage.setItem('request', modifiedValue);
  };

  const isValidURL = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');

    return !!pattern.test(url);
  };

  const removePrefix = (value) => {
    // Vérifier si la valeur commence par "https://" et la modifier si c'est le cas
    if (value.startsWith('https://')) {
      return value.replace('https://', '');
    }
    return value;
  };

  return (
    <div>
      <>
        <div className="container2">
          <div className="left-container2">
            <h1 className="title2">
              Alvo Teaser
              <span className="beta2">BETA</span>
            </h1>
            <p>Votre Teaser personnalisé en 1 clic. Pour tous les business.</p>
            <div className="input-container2">
              <input
                className="chat-input2"
                type="text"
                placeholder="Entrez l'URL du site web de la société..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              />
              <button className="send-button2" onClick={handleSubmit}>
                <img src={sendIcon} alt="Send" className="send-icon" />
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="footer2">
              <p>&copy; Alvo 2023. Tous droits réservés.</p>
            </div>
          </div>
          <div className="right-container2">
            <img src={IM} alt="Info Memo by Alvo" />
          </div>
        </div>
      </>
    </div>
  );
};

export default App2;
