import React, { useEffect, useRef, useState } from "react";
import AlvoGPTUser from "./AlvoGPTUser.svg";
import AlvoGPTLogo from "./AlvoGPTLogo.svg";
import AlvoGPTReset from "./Reset.svg";
import sendIcon from "./Vector.svg";
import "./App1.css";
import ReactMarkdown from "react-markdown"

const ResponsePage = () => {
  const [responseInput, setResponseInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [historic, setHistoric] = useState([{ content: localStorage.getItem("inputText"), role: "user" }]);
  const [apiResponses, setApiResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [typingDots, setTypingDots] = useState("");
  const [error, setError] = useState(false);
  const responseContentRef = useRef(null);
  const inputText = localStorage.getItem("inputText");
  const darkMode = localStorage.getItem("darkMode") === "true";
  const prevInputTextRef = useRef("");

  const liens = document.getElementsByTagName('a');

  for (let i = 0; i < liens.length; i++) {
    const lien = liens[i];
    lien.setAttribute('target', '_blank');
  }
  
  useEffect(() => {
    let dots = "";
    const interval = setInterval(() => {
      dots += ".";
      if (dots.length > 3) {
        dots = "";
      }
      setTypingDots(dots);
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (responseContentRef.current) {
      responseContentRef.current.lastChild.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [conversation, apiResponses]);

  useEffect(() => {
    if (inputText !== prevInputTextRef.current) {
      const fetchApiResponse = async () => {
        try {
          setIsLoading(true);
          setIsLoading2(false);
          setError(false);

          const response = await fetch(
            "https://alvo-gpt-api.alvo.market/api/messages",
            {
              method: "POST",
              headers: {
                Authorization: "Bearer et9@XdKGtt@qX&f#K89D",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                messages: historic,
              }),
            }
          );
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData);

            const newApiResponse = responseData?.data || "";
            if (error) {
              historic.push({ content: "Une erreur est survenue, veuillez réessayer.", role: "assistant" });
            } else {
              historic.push({ content: newApiResponse, role: "assistant" });
            }
            setApiResponses([newApiResponse]);
          } else {
            setError(true);
          }
        } catch (error) {
          console.error("Error:", error);
          setError(true);
        } finally {
          setIsLoading(false);
        }
      };

      fetchApiResponse();
      prevInputTextRef.current = inputText;
    }
  }, [inputText]);

  const handleResponseSend = async () => {
    try {
      setIsLoading2(true);
      if (responseInput) {
        setConversation((prevConversation) => [
          ...prevConversation,
          { userResponse: responseInput, apiResponse: "" },
        ]);
        setIsSending(true);
        setResponseInput("");
      }

      const response = await fetch(
        "https://alvo-gpt-api.alvo.market/api/messages",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer et9@XdKGtt@qX&f#K89D",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messages: historic,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const newApiResponse = responseData?.data || "";
        if (error) {
          historic.push({ content: "Une erreur est survenue, veuillez réessayer.", role: "assistant" });
        } else {
          historic.push({ content: newApiResponse, role: "assistant" });
        }
        setApiResponses((prevResponses) => [...prevResponses, newApiResponse]);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setError(true);
    } finally {
      setIsLoading2(false);
      setIsSending(false);
    }

    setResponseInput("");
    const responseContent = responseContentRef.current;
    responseContent.lastChild.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleBackToHome = () => {
    window.location.href = "/alvogpt";
  };

  return (
    <div className={`response-container ${darkMode ? "dark-mode" : ""}`}>
      <div className="response-content">
        <div className="user-message">
          <div className="center-div">
            <img src={AlvoGPTUser} alt="AlvoGPT User" className="alvogpt-user" />
            <p>{inputText}</p>
          </div>
        </div>
        <div className="api-message">
          <div className={`center-div ${isLoading ? "typing-animation" : ""}`}>
            <img src={AlvoGPTLogo} alt="AlvoGPT Logo" className="alvogpt-logo" />
            {isLoading ? (
              <p className="loading-message">AlvoGPT recherche{typingDots}</p>
            ) : (
              error ? (
                <p className="error-message">Une erreur est survenue, veuillez réessayer.</p>
              ) : (
                <p><ReactMarkdown>{apiResponses[0]}</ReactMarkdown></p>
              )
            )}
          </div>
        </div>
        {conversation.map((message, index) => (
          <div key={index} className="response-message">
            <div className="user-message">
              <div className="center-div">
                <img src={AlvoGPTUser} alt="AlvoGPT User" className="alvogpt-user" />
                <p>{message.userResponse}</p>
              </div>
            </div>
            <div className="api-message" ref={index === conversation.length - 1 ? responseContentRef : null}>
              <div className="center-div">
                <img src={AlvoGPTLogo} alt="AlvoGPT Logo" className="alvogpt-logo" />
                {isSending && index === conversation.length - 1 ? (
                  <p className="sending-message">AlvoGPT recherche{typingDots}</p>
                ) : (
                  error ? (
                    <p className="error-message">Une erreur est survenue, veuillez réessayer.</p>
                  ) : (
                    <p><ReactMarkdown>{apiResponses[index + 1]}</ReactMarkdown></p>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="response-footer">
        <div className={`input-container-response ${isLoading ? "disabled" : ""}${isLoading2 ? "disabled" : ""}`}>
          <button className="btn btn-primary bottom" onClick={handleBackToHome}>
            <img src={AlvoGPTReset} alt="Send" className="send-icon" />
          </button>
          <input
            className={`chat-input ${isLoading ? "disabled" : ""}${isLoading2 ? "disabled" : ""}`}
            type="text"
            placeholder="Votre réponse..."
            value={responseInput}
            onChange={(e) => setResponseInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log(historic)
                historic.push({ content: e.target.value, role: "user" });
                console.log(historic)
                handleResponseSend();
              }
            }}
            disabled={isLoading}
          />
          <button className={`response-send-button ${isLoading ? "disabled" : ""}${isLoading2 ? "disabled" : ""}`} onClick={handleResponseSend}>
            <img src={sendIcon} alt="Send" className="send-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponsePage;