import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './App1.css';
import sendIcon from './Vector.svg';
import IconDark from './Darkmode.svg';

function App1() {
  const [darkMode, setDarkMode] = useState(true);
  const inputRef = useRef('');
  const navigate = useNavigate();

  const handleDarkModeToggle = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Réinitialise la position de défilement au chargement de la page
  }, []);

  const handleSend = () => {
    localStorage.setItem("inputText", inputRef.current.value);
    localStorage.setItem("darkMode", { darkMode }.darkMode.toString());
    inputRef.current.value = '';
    navigate('/alvogpt/response');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleBlockClick = (text) => {
    inputRef.current.value = text;
  };

  const MainPage = () => (
    <div className={`container1 ${darkMode ? 'dark-mode' : ''}`}>
      <h1 className="title">
        AlvoGPT
        <span className="beta1">BETA</span>
      </h1>
      <p className="paragraph">Interrogez l’ensemble du marché en écrivant votre recherche d’entreprises à vendre ou de repreneurs.</p>
      <div className="blocks-container1">
      <p className='test-ex'>Exemples :</p>
        <div className="block" onClick={() => handleBlockClick('Je cherche à racheter un organisme de formation en Ile-de-France')}>
          <h2>Reprendre un organisme de formation</h2>
          <p>Je cherche à racheter un organisme de formation en Ile-de-France</p>
        </div>
        <div className="block" onClick={() => handleBlockClick('Je souhaite reprendre une entreprise de BTP en région PACA')}>
          <h2>Reprendre une entreprise de BTP</h2>
          <p>Je souhaite reprendre une entreprise de BTP en région PACA</p>
        </div>
        <div className="block" onClick={() => handleBlockClick('Je cherche à racheter une agence digitale avec un CA inférieur à 1M€')}>
          <h2>Racheter une agence digitale</h2>
          <p>Je cherche à racheter une agence digitale avec un CA inférieur à 1M€</p>
        </div>
        <div className="block" onClick={() => handleBlockClick('Je cherche à racheter une entreprise de menuiserie à Lyon')}>
          <h2>Racheter une entreprise à Lyon</h2>
          <p>Je cherche à racheter une entreprise de menuiserie à Lyon</p>
        </div>
      </div>
      <div className="footer">
        <div className="input-container">
          <input
            className="chat-input"
            type="text"
            placeholder="AlvoGPT..."
            ref={inputRef}
            onKeyDown={handleKeyDown}
          />
          <button className="send-button" onClick={handleSend}>
            <img src={sendIcon} alt="Send" className="send-icon" />
          </button>
        </div>
        <div className="buttons">
          <button className="btn btn-primary hidden" onClick={handleDarkModeToggle}>
            <img src={IconDark} alt="Send" className="send-icon" />
            {darkMode ? 'Mode clair' : 'Mode sombre'}
          </button>
        </div>
      </div>
    </div>
  );

  return (
      <MainPage/>
  );
}

export default App1;
