import React, { useState, useEffect } from 'react';
import './InfoMemoPage.css';
import IconCopy from './Icone copier.svg';
import IconAlvo from './Logo M alvo avec blur.svg';
import ReactMarkdown from 'react-markdown';
import ReturnIcon from './1.svg';

const InfoMemoPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [anonymizedData, setAnonymizedData] = useState(null);
  const [isTeaserAnonymized, setIsTeaserAnonymized] = useState(false);
  const inputValue = localStorage.getItem("request");

  const sendCardContents = () => {
    const cardContents = {
      card1: {
        title: "Résumé de l'entreprise",
        content: isTeaserAnonymized ? anonymizedData.presentation : data.presentation,
      },
      card2: {
        title: "Actualité du secteur d'activité",
        content: isTeaserAnonymized ? anonymizedData.actuality : data.actuality,
      },
      card3: {
        title: "Forces dans le secteur d'activité",
        content: isTeaserAnonymized ? anonymizedData.strength : data.strength,
      },
      card4: {
        title: "Faiblesses dans le secteur d'activité",
        content: isTeaserAnonymized ? anonymizedData.weakness : data.weakness,
      },
    };

    console.log('Données à envoyer :', cardContents);

    fetch('https://alvo-info-memo-ia-api.alvo.market/api/get-pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer B6rY?#Ebj4D@Az6ac!9b', 
      },
      body: JSON.stringify(cardContents),
      })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Erreur lors de la requête POST.');
        }
    })
    .then((blob) => {
      const pdfUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
  a.href = pdfUrl;
  a.download = 'TeaserviaAlvo.pdf';

  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(pdfUrl);
})
.catch((error) => {
  console.error('Une erreur est survenue lors de la requête POST :', error);
});

  };

  useEffect(() => {
    fetch('https://alvo-info-memo-ia-api.alvo.market/api/messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer B6rY?#Ebj4D@Az6ac!9b',
      },
      body: JSON.stringify({
        url: 'https://' + inputValue,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(inputValue);
        console.log('API response:', responseData);
        setData(responseData.data);
        setAnonymizedData(responseData.anonymizedData);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [inputValue]);

  const handleCopy = (content) => {
    const textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setShowModal(true);

    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  const handleAnonymizeTeaser = () => {
    setIsTeaserAnonymized(!isTeaserAnonymized);
  };

  return (
    <div>
      <div className='headerDiv'>
        <div className='ReturnDiv'><a href='/' className='Return'><img src={ReturnIcon} alt='ReturnIcon'></img> Retour</a></div>
        <div className='ReturnDiv'><div className={`Return-2 ${isTeaserAnonymized ? 'teaser-complete' : 'anonymize-teaser'}`} onClick={handleAnonymizeTeaser}>{isTeaserAnonymized ? 'Obtenir le Teaser Complet' : 'Anonymiser le Teaser'}</div></div>
        <div className='ReturnDiv'><div className={`Return-3 ${isTeaserAnonymized ? 'teaser-complete' : 'anonymize-teaser'}`} onClick={handleAnonymizeTeaser}>{isTeaserAnonymized ? 'Teaser Complet' : 'Anonymiser'}</div></div>
      </div>
      <div className="info-memo-page2">
        {isLoading ? (
          <div className="loader-container2">
            <div className="loader2"></div>
            <p>Chargement de votre Teaser...</p>
          </div>
        ) : (
          (isTeaserAnonymized ? anonymizedData : data) && (
            <>
              <div className="info-memo-card2">
                <h2 className="card-title2">Résumé de l'entreprise</h2>
                <ReactMarkdown className="card-paragraph2" children={isTeaserAnonymized ? anonymizedData.presentation : data.presentation} />
                <div className="card-footer2">
                  <div className="card-action2" onClick={() => handleCopy(isTeaserAnonymized ? anonymizedData.presentation : data.presentation)}>
                    <img src={IconCopy} alt="Icône de Copie" />
                  </div>
                  <div className="card-Alvo2">
                    <img src={IconAlvo} alt="Icône Alvo" />
                  </div>
                </div>
              </div>
              <div className="info-memo-card2">
                <h2 className="card-title2">Actualité du secteur d'activité</h2>
                <ReactMarkdown className="card-paragraph2" children={isTeaserAnonymized ? anonymizedData.actuality : data.actuality} />
                <div className="card-footer2">
                  <div className="card-action2" onClick={() => handleCopy(isTeaserAnonymized ? anonymizedData.actuality : data.actuality)}>
                    <img src={IconCopy} alt="Icône de Copie" />
                  </div>
                  <div className="card-Alvo2">
                    <img src={IconAlvo} alt="Icône Alvo" />
                  </div>
                </div>
              </div>
              <div className="info-memo-card2">
                <h2 className="card-title2">Forces dans le secteur d'activité</h2>
                <ReactMarkdown className="card-paragraph2" children={isTeaserAnonymized ? anonymizedData.strength : data.strength} />
                <div className="card-footer2">
                  <div className="card-action2" onClick={() => handleCopy(isTeaserAnonymized ? anonymizedData.strength : data.strength)}>
                    <img src={IconCopy} alt="Icône de Copie" />
                  </div>
                  <div className="card-Alvo2">
                    <img src={IconAlvo} alt="Icône Alvo" />
                  </div>
                </div>
              </div>
              <div className="info-memo-card2">
                <h2 className="card-title2">Faiblesses dans le secteur d'activité</h2>
                <ReactMarkdown className="card-paragraph2" children={isTeaserAnonymized ? anonymizedData.weakness : data.weakness} />
                <div className="card-footer2">
                  <div className="card-action2" onClick={() => handleCopy(isTeaserAnonymized ? anonymizedData.weakness : data.weakness)}>
                    <img src={IconCopy} alt="Icône de Copie" />
                  </div>
                  <div className="card-Alvo2">
                    <img src={IconAlvo} alt="Icône Alvo" />
                  </div>
                </div>
              </div>
              <div className="new-card2">
                <h1 className="new-card-title2">Téléchargez votre Teaser au format PDF</h1>
                <button className="new-card-button2" onClick={sendCardContents}>Télécharger le Teaser</button>
              </div>
            </>
          )
        )}

        {showModal && (
          <div className={`modal ${showModal ? 'animate-out' : ''}`}>
            <span className="modal-text">Le contenu a été copié dans le presse-papier !</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoMemoPage;
